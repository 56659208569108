import { get, post, deleteMethod, patch } from "./http.js";

// 首页请求
const reqIndex = (params) => {
  let url = "/index";
  return get(url, params);
};

// 获取单集电影请求
export const reqSingle = (params) => {
  let url = "/single";
  return get(url, params);
};

// 获取电视剧片请求
export const reqMultiple = (params) => {
  let url = "/multiple";
  return get(url, params);
};

// 获取用户
const reqLogin = (params) => {
  let url = "/getCode";
  return get(url, params);
};

// 获取用户
const getuserInfo = (params) => {
  let url = "/getuserInfo";
  return post(url, params);
};

// 统一下单
const payment = (params) => {
  let url = "/payments";
  return post(url, params);
};

// 购买后触发的刷新权限
const updateUserAuth = (params) => {
  let url = "/createUserAuth";
  return post(url, params);
};

// 获取首页
const getHouse = (pageNo, pageSize, status, countryId, is_multiset, recommend, popular, is_free, play_year, language_type, categoryId) => {
  let url = "/house";
  url = `/house?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&countryId=${countryId}&is_multiset=${is_multiset}&recommend=${recommend}&popular=${popular}&is_free=${is_free}&play_year=${play_year}&language_type=${language_type}&categoryId=${categoryId}&keyWord=`;
  return get(url);
};

// 获取房屋(按 ID 查询)
const getIdHouse = (params) => {
  let url = "/house/";
  if ("postId" in params) url += params.postId;
  return get(url, params);
};

// 查询
const getIdMovieSelect = (params) => {
  let url = `/getMovieByKey?keyWord=${params}`;
  return get(url, params);
};

// 获取价格
const getTargetPrices = () => {
  let url = "/targetPrices";
  return get(url);
};

/**
 * 类目
 */
// 获取类目
const getCategory = (parent_id) => {
  let url = "/category";
  if (parent_id) {
    url = `/category?parent_id=${parent_id}`
  }
  return get(url);
};

// 发送预览请求
const creatPreview = (params) => {
  let url = "/preview";
  return post(url, params);
};

// 预览
const reqGetFavorites = (params) => {
  let url = "/getFavorites";
  return post(url, params);
};

// 获取评论
export const getComment = (movie_id) => {
  let url = "/comment";
  if (movie_id) {
    url = `/comment?movie_id=${movie_id}`
  }
  return get(url);
};

// 发送评论
export const setComment = (params) => {
  let url = "/comment";
  return post(url, params);
};

// 获取演员
export const getActors = (pageNo, pageSize, sex, nationality) => {
  let url = `/actors?pageNo=${pageNo}&pageSize=${pageSize}&sex=${sex}&nationality=${nationality}&keyWord=`;
  return get(url);
};

// 获取演员（按 ID）
export const getActorsById = (params) => {
  let url = '/getactors';
  return post(url, params);
};

// 获取演员电影
export const getByIdActor = (postId) => {
  let url = `/actor/${postId}`;
  return get(url);
};

// 获取新电影
export const getNewMovie = (pageNo, pageSize) => {
  let url = `/newMovie?pageNo=${pageNo}&pageSize=${pageSize}`;
  return get(url);
};

// 获取首页筛选轮播图
export const getSwiperHome = () => {
  let url = `/swiperHome`;
  return get(url);
};

// 获取用户权限
export const getNewMovieAddress = (params) => {
  let url = '/getNewMovieAddress';
  return post(url, params);
};

// 获取用户权限
export const getUserByUserIdAuth = (params) => {
  let url = '/getUserAuth';
  return post(url, params);
};

// 获取最近影片
export const getRecentlyMovive = (params) => {
  let url = '/getRecentlyMovive';
  return post(url, params);
};

// 制作分享图
export const getGeneratePicture = (params) => {
  let url = '/generatePicture';
  return post(url, params);
};

// 随机获取电影
export const getRandomMovie = (pageSize, recommend, categoryId) => {
  let url = `/randomGetMovie?pageSize=${pageSize}&recommend=${recommend}&categoryId=${categoryId}`;
  return get(url);
};

// 获取视频链接
export const getMovieUrl = (movieUrl, postId) => {
  let url = `/videPlayWai?url=${movieUrl}&postId=${postId}`;
  // let url = `/videPlay?url=${movieUrl}&postId=${postId}`;
  return get(url);
};

export {
  reqIndex,
  reqLogin,
  getuserInfo,
  payment,
  getHouse,
  getIdHouse,
  getIdMovieSelect,
  getTargetPrices,
  getCategory,
  updateUserAuth,
  creatPreview,
  reqGetFavorites
};
