<style>
.q-notification {
  direction: rtl;
  font-family: "Ukij";
}
</style>

<template>
  <div class="detailed bg-blue-grey-10 e-pb-100 height_full_vh">
    <!-- 播放视频 -->
    <div v-if="detailedId > 0 ">
      <!-- <div v-if="detailed?.url == null">
        <video id="my-player" class="video-item" ref="video" @canplay="theCurrenVideo">
          <source :src="detailed?.videoUrl" type="application/x-mpegurl">
        </video>
      </div> -->

      <div style="background-color: #000;" class="relative">
        <div v-if="!videoUrl" style="height: 200px;"></div>
        <video id="my-player" v-else class="video-item" :src="detailed.videoUrl" controls autoplay preload="auto"
          ref="video" @canplay="theCurrenVideo" playsinline="true" webkit-playsinline="true" x5-playsinline="true"
          x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint" tabindex="2"
          mediatype="video" bindplay="" bindpause="" bindended="" bindtimeupdate="" bindwaiting="" binderror="">
        </video>

        <!-- <q-spinner-ios v-if="videoLoading" class="absolute videoLoading" color="primary" size="4em" /> -->
        <!-- <video ref="videoPlayer" id="my-player" class="video-js" style="width: 100%">
        <source :src="videoJsUrl" />
        </video> -->
      </div>

      <!-- 非会员 mask -->
      <div v-show="maskStatus" class="Ukij noVipMask e-t-0 absolute full-width rtl column justify-center items-center"
        :style="'height:' + maskHeight + 'px'">
        <p class="text-white font_size12">سىناق كۆرۈش ۋاقتى توشتى</p>
        <p class="text-white">داۋامىنى يەككە سېتىۋېلىپ ياكى ئالىي ئەزا بولۇپ كۆرۈڭ</p>
        <p class="text-grey-6 font_size12 absolute e-b-0">VIP ئەزالار چەكلىمىسز كۆرەلەيدۇ، كىلەر ئايدا ئاپتۇماتىك
          پۇل تۇتمايدۇ</p>
      </div>

      <!-- 详细、评论、主角 -->
      <div class="e-mt-5">
        <q-tabs v-model="delPagetab" class="bg-primary text-white shadow-2" style="margin-top: 0;">
          <q-tab name="moviDel" class="Ukij font_size18" label="تەپسىلاتى">
          </q-tab>

          <q-tab name="comment" class="Ukij font_size18" label="ئىنكاس">
            <q-badge color="red" floating>{{ commentNum }}</q-badge>
          </q-tab>

          <q-tab name="protagonist" class="Ukij font_size18" label="روللاردا">
          </q-tab>
        </q-tabs>

        <!-- 内容 -->
        <q-tab-panels v-model="delPagetab" animated style="margin-top: 0;">
          <!-- 详细 -->
          <q-tab-panel name="moviDel" class="bg-blue-grey-10">
            <!-- 电影语言 -->
            <div class="row rtl Ukij">
              <!-- <q-btn color="purple" v-show="detailed?.url" @click="onPlayOther(detailed.url)" label="ئۇيغۇچە" />
              <q-btn color="purple" v-show="detailed?.zh_url" @click="onPlayOther(detailed.zh_url)" class="q-mx-sm"
                label="خەنزۇچە" />
              <q-btn color="purple" v-show="detailed?.or_url" @click="onPlayOther(detailed.or_url)"
                label="ئەسلى ئاۋاز" /> -->



              <q-btn color="purple" v-if="detailed?.language_type == 3" label="ئەسلى ئاۋاز" />
              <q-btn color="purple" v-if="detailed?.language_type == 1" label="ئۇيغۇرچە" />
              <q-btn color="purple" v-if="detailed?.language_type == 2" label="خەنزۇچە" />
            </div>

            <!-- 提示 -->
            <div class="Ukij q-py-md bg-white bg-blue-grey-10" v-if="!isPlay">
              <p class=" rtl text-grey-6 text-center">15مىنۇت سىناق كۆرەلەيسىز، داۋامىنى سېتىۋېلىپ كۆرۈڭ !</p>
              <div class="row justify-center font_size12">
                <div class="bg-deep-orange text-white q-px-md q-py-sm border-r-8 q-mx-sm rtl" @click="toMemberPage">بىر
                  كۈنلۈك
                  سېتىۋېلىش</div>
                <div class="bg-amber-5 q-px-md q-py-sm border-r-8" @click="toMemberPage">ئالىي ئەزا بولۇش</div>
              </div>
            </div>

            <!-- 简介 -->
            <div class="bg-white bg-blue-grey-10 rtl Ukij q-py-md font_size12" v-if="videoCon">
              <!-- 封面以及参数 -->
              <div class="row">
                <!-- 封面 -->
                <div class="width_30p overflow-h border-r-8">
                  <q-img :src="detailed.image_url" />
                </div>

                <!-- 参数 -->
                <div class="q-mr-md column justify-between">
                  <!-- 电影名 -->
                  <div class="text-grey-5 row">
                    <div class="text-ellipsis width_200">{{ detailed.name }} ({{ detailed.name_zh }})</div>
                  </div>
                  <!-- 制作国 -->
                  <div class="text-grey-5">
                    <span class="q-ml-sm">رايون:</span>
                    <span class="bg-grey-3 text-grey-7 e-p-x3-y5 border-r-8 e-ml-5"
                      v-for="country of detailed.country">{{
                      country }}</span>
                  </div>
                  <!-- 电影类 -->
                  <div class="text-grey-5"><span class="q-ml-sm">تۈرى:</span>
                    <span class="bg-grey-3 text-grey-7 e-p-x3-y5 border-r-8 e-ml-5 inline-block"
                      v-for="category of detailed.category">{{ category }}</span>
                  </div>
                  <!-- 电影时间长 -->
                  <div class="text-grey-5">
                    <span class="q-ml-sm">ۋاقتى:</span>
                    <span class="bg-grey-3 text-grey-7 e-p-x3-y5 border-r-8">{{ detailed.playTime }} قويۇلغان </span>
                  </div>
                  <!-- 评分 -->
                  <div class="text-grey-5 row">
                    <span class="q-ml-sm">باھا :</span>
                    <div class="row bg-grey-3 e-p-x-5 border-r-8">
                      <span><q-rating v-model="oneStart" size="1.1em" :max="1" color="warning" /></span>
                      <span class="text-grey-7" style="padding: 1px 3px;"> {{ detailed.score }} </span>
                    </div>
                    <div class="row align-center bg-grey-3 e-p-x-5 border-r-8 q-mr-sm">
                      <q-img :src="`${reqUrlData.url.icon_url}douBan.jpg`" class="height_15 width_15"
                        style="border-radius: 33%;" />
                      <span class="text-grey-7" style="padding: 1px 3px;">دوۋبەن باھا</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 电影讲解 -->
              <div class="text-grey-6 q-mt-md" v-if="detailed.content.length !== 0">فىلىم ھەققىدە</div>
              <div class="text-grey-5 q-mt-sm text-justify" v-if="detailed.content.length !== 0">{{ detailed.content }}
              </div>

              <!-- 分割线 -->
              <q-separator class="q-mt-md" />

              <!-- 多集 -->
              <div v-if="detailed.is_multiset == 1">
                <p class="text-grey-7 q-my-sm font_size16">قىسىملىرى:</p>
                <div class="row">
                  <div @click="onclickMultiSet(item)"
                    class="row relative bg-grey-3 text-grey-7 q-py-sm q-mb-sm multiSetsWidth multiSetsLeft border-r-8"
                    :class="[active_multiset_num == item.id ? 'border-b1-main' : 'border-ba1-e']"
                    v-for="item of detailed.multiSets">
                    <div v-if="item.is_free == 1" class="absolute e-t-0 e-l-0 text-yellow-14 e-ml-3 font_size11">VIP
                    </div>
                    <div
                      :class="[active_multiset_num == item.id ? 'text-main-color' : '', 'font_size20 full-width text-center']">
                      {{ item.multiset_num }}
                    </div>
                    <div class="loader absolute b3 r3" v-if="active_multiset_num == item.id"></div>
                  </div>
                </div>
              </div>

              <!-- 收藏、喜欢 -->
              <div class="q-mt-md row justify-between">
                <div v-for="(item, index) in likeList" :key="index" class="column justify-center items-center"
                  @click="onclickLikeList(item)">
                  <div class="width_40 height_40"><q-img :src="item.icon" /></div>
                  <div class=" text-grey-4">{{ item.name }}</div>
                </div>
              </div>
            </div>

            <!-- 推荐电影 -->
            <div class="bg-white bg-blue-grey-10 rtl Ukij q-py-md">
              <!-- 标题 -->
              <div class="row">
                <div class="q-ml-sm"><q-img :src="`${reqUrlData.url.vipAtushIconUrl}/tuijian01.svg`" width="20px"
                    height="20px" />
                </div>
                <div class="text-grey-5">مۇناسىۋەتلىك تەۋسىيەلەر</div>
              </div>

              <!-- 电影 -->
              <div class="q-mt-md row q-mb-xl">
                <div class="width_30p q-mb-md movie_box" v-for="(item, index) of movies" :key="index"
                  @click="playMovie(item)">
                  <div class="height_150 q-mb-sm border-r-8 overflow-h relative-position">
                    <q-img :src="item.image_url" class="full-height" />
                    <div v-if="item.is_free == 1"
                      class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
                      style="border-radius: 0 0 0 15px;">
                      <div class="e-ml-5 fontW-900">VIP</div>
                    </div>
                    <div v-if="item.is_free == 0"
                      class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
                      style="border-radius: 0 0 0 15px;">
                      <div class="e-ml-5 Ukij">ھەقسىز</div>
                    </div>
                    <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size12">
                      <div class="e-ml-5 row align-center">
                        <div class="e-mx-3">{{ item.score }}</div>
                        <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
                      </div>
                    </div>
                  </div>
                  <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{ item.name }}</div>
                  <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
                    {{ item.play_year}}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
                    item.category[0].name
                    : '' }}
                  </div>
                  <div class="row justify-between" style="margin-top: 5px;" v-for="i of item.language_type">
                    <!-- <q-badge v-show="item.language_type == 1" color="grey-7"
                      class="Ukij font font_size8">ئۇيغۇچە</q-badge>
                    <q-badge v-show="item.language_type == 2" color="grey-7"
                      class="Ukij font font_size8">خەنزۇچە</q-badge>
                    <q-badge v-show="item.language_type == 3" color="grey-7" class="Ukij font font_size8">ئەسلى
                      ئاۋاز</q-badge> -->



                    <q-badge color="grey-7" v-if="i == 1" class="Ukij font font_size8">ئۇيغۇچە</q-badge>
                    <q-badge color="grey-7" v-if="i == 3" class="Ukij font font_size8">ئەسلى ئاۋاز</q-badge>
                  </div>
                </div>
              </div>
            </div>
          </q-tab-panel>

          <!-- 评论 -->
          <q-tab-panel name="comment" class="bg-blue-grey-10">
            <div class="text-grey-5 Ukij rtl" v-if="commentsArr.length == 0">ئىنكاس يوقكەن ~</div>

            <!-- 评论 -->
            <div v-else class="overflow-scroll height_300 e-mb-10">
              <div class="row rtl align-center e-mb-10 border-b-2 e-pb-5" v-for="item in commentsArr">
                <div>
                  <q-avatar size="36px">
                    <img :src="item.user_info.headimgurl">
                  </q-avatar>
                </div>
                <div class="text-white q-mx-sm">
                  <span class="block">{{ item.user_info.nickname }}</span>
                  <span class="block Ukij">{{ item.comment_text }}</span>
                </div>
              </div>
            </div>

            <!-- 发评论 -->
            <div class="row fixed e-b-80" style="width: calc(100% - 32px);">
              <q-input class="Dcontents" outlined v-model="commentText" bg-color="white" />
              <q-btn class="Ukij" color="primary" label="يوللاش" @click="sendComment" style="margin-left: -3px;" />
            </div>
          </q-tab-panel>

          <!-- 主角 -->
          <q-tab-panel name="protagonist" class="bg-blue-grey-10">
            <div class="text-grey-5 Ukij rtl" v-if="actorsDataArr.length == 0">ئاكتىيور قوشۇلمىدى ~ ~ ~</div>
            <div v-else class="row wrap rtl">
              <div v-for="item of actorsDataArr" class="Dcolumn align-center" @click="onClickActor(item)">
                <div class="q-ml-sm"> <q-img class="width_100 height_100" style="border-radius: 100px;"
                    :src="item.image_url" /> </div>
                <div class="Ukij text-grey-5">{{ item.name }}</div>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>

    </div>
  </div>

  <!-- 分享转发对话框 -->
  <q-dialog v-model="shareDialog">
    <q-card class="full-width">
      <q-card-section>
        <q-img :src="imageBuffer" />
        <div class="q-mt-md Ukij text-center text-red-7">ئۈستىدىكى تەشۋىق رەسىمنى ساقلىۋېلىپ ھەمبەھىرلەڭ</div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="center" class="bg-white text-teal">
        <q-btn class="Ukij full-width full-height" flat label="بولىدۇ بىلدىم" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- Tab -->
  <Tab></Tab>
</template>

<script>
// import Player from 'xgplayer';
import videojs from 'video.js';
import { getHouse, getIdHouse, creatPreview, getComment, setComment, getActorsById, reqLogin, getGeneratePicture, getMovieUrl } from '../../request/api';
import { Notify } from 'quasar';
import { ref } from 'vue';
import Tab from '../../components/tab/Tab.vue';
import { reqUrl } from '@/request/url.js';
export default {
  name: 'detailed',
  components: {
    Tab
  },
  data () {
    return {
      pageType: ref(null),
      categoryId: null,
      detailedId: null,
      detailed: null,
      videoUrl: false,
      videoName: false,
      ratingModel: 3,
      active_multiset_num: 1,
      movies: [],
      commentsArr: [],
      commentNum: '10+',
      maskHeight: 0,
      maskStatus: false,
      videoCon: false,
      videoLoading: false,
      delPagetab: 'moviDel',
      pageNo: 1,
      isPlay: false,
      oneStart: 1, // 一个星星
      reqUrlData: reqUrl,
      likeList: [
        { id: 1, name: 'بەھىرلەش', icon: `${reqUrl.url.vipAtushIconUrl}wechat_forward.svg`},
        { id: 2, name: 'ساقلاش', icon: `${reqUrl.url.vipAtushIconUrl}shoucang.svg`},
        { id: 3, name: 'يارىدى', icon: `${reqUrl.url.vipAtushIconUrl}dianzan.svg`},
        { id: 4, name: 'ئەزالىق', icon: `${reqUrl.url.vipAtushIconUrl}vip_top.svg`},
      ],
      shareDialog: false,
      imageBuffer: null,
      videoLength: 0, // 电影长度
      videoDuration: 0, // 电影长度
      movieType: 0, // 电影类型
      commentText: '', // 评论
      actorsDataArr: [],
      player: null,
      recommend: -1,
      popular: -1,

      videoJsAdUrl: '',
      videoJsUrl: '',
      // 视频模块
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            withCredentials: true,
            //type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            type: "application/x-mpegurl",
            src: "",
          },
        ],
        controls: true,
        hls: true,
        poster:
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg", // 你的封面地址
        width: 600, // 播放器宽度
        height: 600,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          progressControl: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true, // 全屏按钮
        },
        flash: { hls: { withCredentials: true } },
        html5: { hls: { withCredentials: true } },
      },
    }
  },
  methods: {
    // 当前播放视频
    theCurrenVideo() {
      let my_player = document.getElementById('my-player')
      let videoLength = this.$refs.video.duration // 视频总长度
      // let video1 = this.$refs.video.currentTime // 当前播放时间
      
      this.maskHeight = my_player.clientHeight
      // this.videoLength = parseInt(`${this.videoDuration / 60}`, 10)

      // if(videoLength != NaN) this.videoLength = parseInt(videoLength / 60, 10)
      if(videoLength) {
        this.videoLength = parseInt(videoLength / 60, 10)
      } else {
        this.videoLength = 0;
      }

      let _this = this;
      my_player.addEventListener("timeupdate", function() {
        let timeDisplay = Math.floor(my_player.currentTime);
        if(!_this.isPlay && timeDisplay >= 10) {
          my_player.pause()
          // _this.player.pause()
          _this.maskStatus = true;
        }
      })
    },

    handleTouchStart(event) {
      if (this.shouldPreventDefaultCondition) {
        event.preventDefault();
      }
    },

    // 播放相关电影
    playMovie(item) {
      this.scroll_top();
      this.detailedId = item.id;

      let data = { id: item.id }
      this.$router.push({
        path: this.$route.path, // 保持当前路径不变
        query: data, // 添加或更新参数
      }).then(() => {
        // 刷新页面
        this.$router.go(0);
      });
      
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    // 获取地址兰参数
    getQueryParam() {
      this.pageType = this.$route.query.type
      this.detailedId = this.$route.query.id
      this.categoryId = this.$route.query.category
      this.getPost()
    },

    // 获取详细内容
    async getPost() {
      let data = { postId: this.detailedId }
      getIdHouse(data).then(async res => {
        if (res.status == 200) {
          this.detailed = await res.data.data
          this.playerOptions.sources[0].src = await res.data.data.videoUrl
          this.posterUrl = await res.data.data.image_url
          this.videoJsUrl = await res.data.data.videoUrl
          this.isPlay = await res.data.isPlay
          if (res.data.data.videoUrl && res.data.data.name){     
            // && == 和  ھەمدە
            this.videoUrl = true
            this.videoCon = true
            if (res.data.data.is_multiset == 1) {
              this.active_multiset_num = res.data.data.multiSets[0].id;
            } 
          }

          const timestamp = this.detailed.playTime;
          const date = new Date(timestamp * 1000);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          this.detailed.playTime = `${day}-${month}-${year}`;

          this.getRelatedMovies(); // 获取相关电影
        }
      })
    },

    // 获取相关电影
    getRelatedMovies() {
      let pageNo = this.pageNo
      let categoryId = this.categoryId
      let recommend = this.recommend;
      let popular = this.popular;
      let status = 1
      // pageNo, pageSize, status, countryId, is_multiset, recommend, popular, is_free, play_year, language_type, categoryId
      getHouse(pageNo, 6, status, -1, -1, recommend, popular, -1, -1, -1, categoryId).then(res => {
        this.movies = res.data.data
      }).catch()
    },

    // 到会员页面
    toMemberPage() {
      this.$router.push({ path: '/member' })
    },

    // 喜欢、收藏
    onclickLikeList(item) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      let type = 1; // 喜欢、收藏
      let content_id = this.detailedId;
      let user_id = userInfo.id;
      let data = { user_id, type, content_id }

      switch (item.id) {
        case 1:
          let data1 = { params: window.location.href, postId: this.detailedId }
          
          getGeneratePicture(data1).then(async res => {
            // 将PNG数组转换为Base64数据URI
            const byteArray = new Uint8Array(res.data.finalBuffer.data);
            const binaryString = byteArray.reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            );
            const base64Data = btoa(binaryString);
            this.imageBuffer = `data:image/png;base64,${base64Data}`;

          }).catch(err => {})
          this.shareDialog = true;
          break
        case 2:
          creatPreview(data).then(res => {
            if (res.status == 200) {
              Notify.create('ساقلاش مۇۋاپىقىيەتلىك بولدى !')
            }
          }).catch(err => {})
           break
        case 3:
          creatPreview(data).then(res => {
            if (res.status == 200) {
              Notify.create('ياقتۇرغىنىڭىزغا رەھمەت !')
            }
          }).catch(err => {})
           break
        case 4:
          this.$router.push({ path: '/member' })
           break
        default:
          console.log("默认")
           break
      }
    },

    // 发送预览请求
    creatPreviewReq() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      let type = 0; // 预览
      let content_id = this.detailedId;
      let user_id = userInfo.id;
      // let user_id = 1
      let data = { user_id, type, content_id }
      creatPreview(data).then(res => {}).catch(err => {})
    },

    // 获取评论
    getComments() {
      let movie_id = this.detailedId
      getComment(movie_id).then(res => {
        if(res.status == 200) {
          this.commentsArr = res.data;
          let commentNumLength = res.data.length;
          let commentCom = ''
          if (commentNumLength > 0) {
            commentCom = `${commentNumLength}+`
          } else {
            commentCom = '0'
          }
          this.commentNum = commentCom;
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },

    // 获取演员
    getProtagonist() {
      if(!this.detailed.actors) return;
      let data = { postId: this.detailed.actors }
      getActorsById(data).then(res => {
        if(res.status == 200) {
          this.actorsDataArr = res.data;
        }
      }).catch(err => {})
    },

    // 往演员详细页面
    onClickActor (item) {
      this.$router.push({ path: '/star', query: { postId: item.id } });
    },

    // 发送评论
    sendComment() {
      let comment_text = this.commentText;
      let movie_id = this.detailedId;

      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      let user_id = userInfo.id
      // let user_id = 1
      let data = { comment_text, movie_id, user_id };
      setComment(data).then(res => {
        if(res.status == 200) {
          this.getComments();
        }
      }).catch(err => {
        console.log('err: ', err);
      })
      this.commentText = '';
    },

    // 跳集
    onclickMultiSet(item) {
      getMovieUrl(item.url, item.id).then(async res => {
        console.log("res::", res)
        if(res.status == 200) {
          this.scroll_top();
          this.active_multiset_num = item.id;
          this.maskStatus = false;
          this.isPlay = res.data.isPlay;
          this.detailed.videoUrl = await res.data.videoUrl;
          // this.detailed.videoUrl = await res.data;
        }
      }).catch( err => { console.log("err::", err) } )

      this.scroll_top();
      // this.active_multiset_num = item.id;
      // this.detailed.videoUrl = item.videoUrl;
      // this.player.src({ src: item.videoUrl });
      // this.player.play();
    },

    // 播放其他语言版本
    onPlayOther(item) {
      getMovieUrl(item).then(async res => {
        if (res.status == 200) {
          this.scroll_top();
          this.detailed.videoUrl = await res.data.videoUrl;
        }
      }).catch(err => { console.log("err::", err) })

      this.scroll_top();
    },

    // 登录请求
    reqLoginFun() {
      reqLogin().then(res => {
        if (res.status == 200) {
          window.location.href = res.data
        }
      }).catch()
    },

    // 把 code 和 用户信息 存到缓存
    isCode() {
      if (window.location.search.includes('code=')) {
        localStorage.setItem('isCode', this.$route.query.code)
        let code = this.$route.query.code
        let data = { code }
        getuserInfo(data).then(res => {
          const userInfo = res.data.data
          const token = res.data.token
          localStorage.setItem("userInfo", JSON.stringify(userInfo))
          localStorage.setItem("token", token)
        }).catch()
      }
      return localStorage.getItem("isCode") !== null
    },

    initPlayer() {
      this.player = this.$video(this.$refs.videoPlayer, {}, () => {
        console.log('Video player initialized.');
      });
    },

    // 监听电影播放时间
    async timeUpdateHandler (event) {
      let currentTime = await event.target.player.cache_?.currentTime;
      this.maskHeight = event.target.clientHeight;
      if (currentTime) {
        if (!this.isPlay && currentTime >= 10) {
          this.player.pause();
          this.maskStatus = true;
        }
      }
      this.videoLoading = false;
    },

  },
  created() {
    if (!this.isCode()) this.reqLoginFun()
    this.scroll_top()
    this.getQueryParam()
    if(this.detailedId) this.creatPreviewReq()
  },
  mounted () {
    setTimeout(() => {
      this.player = videojs(this.$refs.videoPlayer, {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        tabindex: "2",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        touchmoveListener: false, // 如果有此选项
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: this.videoJsUrl,
            canplay: this.theCurrenVideo(),
          },
        ],
        controls: true,
        hls: true,
        // poster: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg", // 你的封面地址
        poster: this.posterUrl, // 你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          progressControl: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true, // 全屏按钮
        },
        flash: { hls: { withCredentials: true } },
        html5: { hls: { withCredentials: true } },
      }, () => {
        // 确保元数据加载完成后获取时长
        this.player.on('loadedmetadata', () => {
          this.videoDuration = this.player.duration();
          this.theCurrenVideo();
        });

        this.player.on('timeupdate', this.timeUpdateHandler);
        this.player.log('onPlayerReady', this);
        this.player.src({ src: this.videoJsUrl })
        // this.player.on('ended', () => {
        //   this.player.src({ src: this.videoJsUrl })
        // });

      });
    }, 2000)
    
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    delPagetab() {
      if (this.delPagetab == 'comment') this.getComments()
      if (this.delPagetab == 'protagonist') this.getProtagonist()
    },
  }
}
</script>

<style scoped>
.multiSetsWidth { width: 13.9%; }
.multiSetsLeft { margin-left: 3.3%; }
.multiSetsLeft:nth-child(6n) { margin-left: 0; }
.video-item{
  width: 100%;
}
.movie_box {
  margin-left: 5%;
}
.movie_box:nth-child(3n){
  margin-left: 0;
}
.noVipMask {
  background-color: rgba(47, 47, 47, 0.9);
}
.b3 { bottom: 3px; }
.r3 { right: 3px; }
.loader {
  /* width: 45px; */
  height: 10px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#01D5CB 0 0);
  background: 
    var(--c) 0%   100%,
    var(--c) 50%  100%,
    var(--c) 100% 100%;
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  20% {background-size: 20% 60% ,20% 100%,20% 100%}
  40% {background-size: 20% 80% ,20% 60% ,20% 100%}
  60% {background-size: 20% 100%,20% 80% ,20% 60% }
  80% {background-size: 20% 100%,20% 100%,20% 80% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}
.videoLoading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
